import styles from "./styles/Main.module.scss";
import React, { useRef, useState, useEffect } from "react";
import Wallet from "./wallet.js";
import MDVlogo from "./assets/Logo_MDV.png";
import screen from "./assets/Computer.png";
import whatkeyImg from "./assets/whatkey.png";
import willyouImg from "./assets/willyou.png";
import card1 from "./assets/KeyCardsforScreen/CD__0000_0001.png";
import card2 from "./assets/KeyCardsforScreen/CD__0001_0001.png";
import card3 from "./assets/KeyCardsforScreen/CD__0002_0001.png";
import card4 from "./assets/KeyCardsforScreen/CD__0003_0001.png";
import card5 from "./assets/KeyCardsforScreen/CD__0004_0001.png";
import card6 from "./assets/KeyCardsforScreen/CD__0005_0001.png";
import card7 from "./assets/KeyCardsforScreen/CD__0006_0001.png";
import twitterIcon from "./assets/FooteRIcons/Twitter1.png";
import discordIcon from "./assets/FooteRIcons/Discord1.png";
import nexoLogo from "./assets/NexoGames.png";
import topIcon from "./assets/FooteRIcons/backtotop.png";
import { motion } from "framer-motion";

export default function Main() {
  const scrollupRef = useRef();
  const [timer, setTimer] = useState(["00", "00", "00", "00"]);
  let format = (nb) => {
    if (nb < 10) {
      return "0" + nb.toString();
    } else {
      return nb;
    }
  };
  useEffect(() => {
    let targetDate = new Date("Dec 18, 2021 18:00:00").getTime();
    // eslint-disable-next-line
    let x = setInterval(() => {
      let now = new Date().getTime();
      let dis = targetDate - now;
      let day = Math.floor(dis / (1000 * 60 * 60 * 24));
      let hrs = Math.floor((dis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let min = Math.floor((dis % (1000 * 60 * 60)) / (1000 * 60));
      let sec = Math.floor((dis % (1000 * 60)) / 1000);
      setTimer([format(day), format(hrs), format(min), format(sec)]);
    }, 1000);
  }, []);
  return (
    <div className={styles.container}>
      <div ref={scrollupRef}></div>
      <header>
        <img
          onClick={() => window.open("https://milliondollarvault.io/", "_self")}
          style={{ cursor: "pointer" }}
          src={MDVlogo}
          alt=""
        />
      </header>
      <main>
        <div>
          <Wallet />
          <p className={styles.howto}>
            <span>HOW TO MINT A KEY CARD:</span>
            <br />
            <br /> 1: Connect your wallet
            <br /> 2: Select your Mint Quantity <br />
            3: Click on the button to mint your Key Cards
            <br /> 4: Accept the transaction on your wallet. <br />
            5: Welcome to the MDV, view your key cards on Opensea.
          </p>
        </div>
        <div>
          <img src={screen} alt="" />
          <img src={whatkeyImg} alt="" />
          <img src={willyouImg} alt="" />
          <motion.img
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              times: [0, 0.5, 1],
              duration: 3,
              repeatType: "reverse",
              repeat: Infinity,
              repeatDelay: 15,
            }}
            src={card1}
            className={styles.cardImg}
            alt=""
          />
          <motion.img
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              times: [0, 0.5, 1],
              duration: 3,
              repeatType: "reverse",
              repeat: Infinity,
              repeatDelay: 15,
              delay: 2.5,
            }}
            src={card2}
            className={styles.cardImg}
            alt=""
          />
          <motion.img
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              times: [0, 0.5, 1],
              duration: 3,
              repeatType: "reverse",
              repeat: Infinity,
              repeatDelay: 15,
              delay: 5,
            }}
            src={card3}
            className={styles.cardImg}
            alt=""
          />
          <motion.img
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              times: [0, 0.5, 1],
              duration: 3,
              repeatType: "reverse",
              repeat: Infinity,
              repeatDelay: 15,
              delay: 7.5,
            }}
            src={card4}
            className={styles.cardImg}
            alt=""
          />
          <motion.img
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              times: [0, 0.5, 1],
              duration: 3,
              repeatType: "reverse",
              repeat: Infinity,
              repeatDelay: 15,
              delay: 10,
            }}
            src={card5}
            className={styles.cardImg}
            alt=""
          />
          <motion.img
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              times: [0, 0.5, 1],
              duration: 3,
              repeatType: "reverse",
              repeat: Infinity,
              repeatDelay: 15,
              delay: 12.5,
            }}
            src={card6}
            className={styles.cardImg}
            alt=""
          />
          <motion.img
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              times: [0, 0.5, 1],
              duration: 3,
              repeatType: "reverse",
              repeat: Infinity,
              repeatDelay: 15,
              delay: 15,
            }}
            src={card7}
            className={styles.cardImg}
            alt=""
          />
        </div>
      </main>
      <footer>
        <div>
          <div>
            <a
              href="https://twitter.com/MDVault"
              rel="noreferrer"
              target="_blank"
            >
              <img src={twitterIcon} alt="" />
            </a>
            <a
              href="https://discord.com/invite/milliondollarvault"
              rel="noreferrer"
              target="_blank"
            >
              <img src={discordIcon} alt="" />
            </a>
          </div>
          <a href="https://milliondollarvault.io/terms">Terms & Conditions</a>
          <a href="https://milliondollarvault.io/privacy">Privacy Policy</a>
        </div>
        <div>
          <div>
            <img
              onClick={() =>
                window.open("https://milliondollarvault.io/", "_self")
              }
              src={MDVlogo}
              alt=""
            />
            <a href="https://nexogames.com/" rel="noreferrer" target="_blank">
              <img src={nexoLogo} alt="" />
            </a>
          </div>
          <hr />
          <p>© 2021 Copyright Million Dollar Vault. All rights reserved.</p>
        </div>
        <div>
          <p>BACK TO TOP</p>
          <img
            onClick={() => {
              scrollupRef.current.scrollIntoView({ behavior: "smooth" });
            }}
            src={topIcon}
            alt=""
          />
        </div>
      </footer>
    </div>
  );
}
